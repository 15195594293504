import { Injectable } from "@angular/core";

import { environment } from "../../environments/environment";
import { HttpClient, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

export interface FcmToken {
  token: string;
  language: string;
  platform: string;
  userAgent: string;
  customer_id?: number;
}

export interface OrderResult {
  customer: any;
  order: any;
  lock: boolean;
  items: any[];
}

@Injectable()
export class OrderService {
  headers: any;

  apiUrlModel: string;

  constructor(private http: HttpClient) {
    this.headers = {
      headers: new HttpHeaders()
        .set("app", "cantine")
        .set("secretkey", environment.api_url_php.secretkey)
        .set("Accept", "application/json"),
    };

    this.apiUrlModel = environment.api_url_php.apigesthost + "model/";
  }
  getOrder(
    id: string,
    securityCode: string
  ): Observable<HttpEvent<OrderResult>> {
    const params = `?securityCode=${securityCode}`;
    return this.http.get<OrderResult>(
      `${environment.api_url_php.apigesthost}order/getorderClienti/${id}${params}`,
      this.headers
    );
  }
  getOrderByIdstorico(id: string) {
    const model = "orders";
    return this.http.get(environment.api_url + model + "/?idstorico=" + id);
  }
  getOrderById(id: number): Observable<any> {
    const model = "orders";
    const url = `${this.apiUrlModel + model}/${id}`;
    return this.http.get<any>(url, this.headers);
  }
  getProduct(code: string) {
    const url = environment.api_url_php.apigesthost + "products/getOne/" + code;
    return this.http.get(url, this.headers);
  }

  saveOrder(order: any) {
    const orderToSave = {
      order: order.order,
      customer: order.customer,
      user: "cliente",
      action: "conferma online",
    };

    return this.http.put(
      environment.api_url_php.apigesthost + "orders/saveonline/it",
      orderToSave,
      this.headers
    );
  }
  saveAnagrafica(anagrafica: any) {
    return this.http.put(
      environment.api_url_php.apigesthost + "model/customers/" + anagrafica.ID,
      anagrafica,
      this.headers
    );
  }
  getAnagraficaModel(id: string, securityCode: string) {
    const params = `?securityCode=${securityCode}`;
    return this.http.get(
      `${environment.api_url_php.apigesthost}customer/getAnagraficaOnline/${id}${params}`,
      this.headers
    );
  }

  createEsitiTel(object: any) {
    const model = "esititelefonate";
    return this.http.post(this.apiUrlModel + model, object, this.headers);
  }

  getAnagrafica(id: string) {
    const model = "customers";
    return this.http.get(environment.api_url + model + "/?idstorico=" + id);
  }

  getEsitiOrdini() {
    return this.http.get(
      environment.api_url_php.apigesthost + "esiti/ordini/it",
      this.headers
    );
  }
  getEsiti(): Observable<any> {
    return this.http.get<any>(
      environment.api_url_php.apigesthost + "model/esitiordini",
      this.headers
    );
  }
  sendesito(esitoToSave: any) {
    return this.http.post(
      environment.api_url_php.apigesthost + "esiti/inserisci/it",
      esitoToSave,
      this.headers
    );
  }

  validateOrder(order: any) {
    return this.http.post(
      environment.api_url_php.apigesthost + "verificafatture/xml/0",
      order,
      this.headers
    );
  }

  saveToken(tokenObj: FcmToken) {
    return this.http.post(
      environment.api_url_php.apigesthost + "fcmtokens/save",
      tokenObj,
      this.headers
    );
  }

  getDdt(hash: string) {
    return this.http.get(
      environment.api_url_php.apigesthost + "ddt/download/" + hash,
      this.headers
    );
  }
}
