import { Pipe, PipeTransform } from '@angular/core';
import { TranslationsService } from './translations.service';

@Pipe({
  name: 'translation'
})
export class TranslationPipe implements PipeTransform {

  // import Translation service
  constructor(private trans: TranslationsService) {}

  transform(value: string, ...args: unknown[]): unknown {
    return this.trans.t(value);
  }

}
