import { Component } from '@angular/core';
import { TranslationsService } from './services/translations.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(
    private trans: TranslationsService
  ) {
    this.trans.setLanguage(environment.lang);
  }

}
