<div class="main-container">
  <mat-toolbar color="primary" class="topbar telative">
    <div class="navbar-header">
      <a class="navbar-brand" routerLink="/dashboard">
        <span style="color: white"> Daydreams</span>
      </a>
    </div>

    <span fxFlex></span>

    <app-header></app-header>

    <div class="right">Servizio clienti info@daydreams.it</div>
  </mat-toolbar>

  <mat-sidenav-container
    class="example-sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
  >
    <mat-sidenav-content class="page-wrapper">
      <div class="page-content">
        <router-outlet><app-spinner></app-spinner></router-outlet>
      </div>

      <footer style="text-align: center; font-size: 11px">
        <p>
          Distributore esclusivo per l'Italia dei prodotti Daydreams: Cantine
          della Corte s.r.l. società unipersonale <br />
          Via Guicciardini 2 - 31049 - VALDOBBIADENE (TV) ITALY Tel. 04231916706
          Fax 0423-34.99.01 E-mail info@daydreams.it <br />
          C.F. e P.IVA 03645480264 - Registro imprese TV 03645480264 - REA TV
          286945 Cap. Soc. 50.000 Euro - i.v.
        </p>
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
