import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslationPipe } from "../services/translation.pipe";
import { SpinnerComponent } from "./spinner.component";
import { AppHeaderComponent } from "../layouts/full/header/header.component";
import { AppSidebarComponent } from "../layouts/full/sidebar/sidebar.component";
import { SecurityCodeComponent } from "../components/security-code/security-code.component";
import { MaterialModule } from "../Material-module";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxLoadingXModule } from "ngx-loading-x";
import { PrivacyLinkComponent } from "../components/privacy-link/privacy-link.component";
import { ProductDetailComponent } from "../components/product-detail/product-detail.component";

const COMPONENTS = [
  TranslationPipe,
  SpinnerComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  SecurityCodeComponent,
  PrivacyLinkComponent,
  ProductDetailComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxLoadingXModule,
  ],
  exports: [...COMPONENTS],
})
export class SharedModule {}
