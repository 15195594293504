<div *ngIf="product">

    <a href="javascript:void(0)" (click)="showDettagliArticolo(product)">{{product.detail.product.DescrizioneArticolo}}</a>

    <div *ngIf="product.showDettagli">
                  
        <ul *ngIf="product.detail.product.esplodi === 'V'">

            <li *ngFor="let item of product.detail.distintaBase">
              {{item.descrizione}}
            </li>
          </ul>
      
          <ul *ngIf="product.detail.product.esplodi === 'M'">
            <li>
              {{product.detail.product.DescrizioneArticolo}}
            </li>
          </ul>
      
          <ul *ngIf="product.detail.product.esplodi === 'K'">
            <li *ngFor="let item of product.detail.distintaSingola">
              {{item.descrizione}}
            </li>
          </ul>
    </div>
</div>
                     