export const environment = {
  production: true,
  base_url: {
    it: "https://clienti.cantinedellacorte.com/",
    de: "https://clientide.cantinedellacorte.com/",
  },
  lang: "it",
  api_url: "https://api2.cantinedellacorte.com/",
  socketUrl: "https://api2.cantinedellacorte.com",
  api_url_php: {
    apigesthost: "",
    urls: {
      it: "https://apigestdev.cantinedellacorte.it/",
      de: "http://apigest.cantinedellacorte.test/",
      default: "https://apigestdev.cantinedellacorte.it/",
    },
    app: "cantine",
    secretkey: "wasV1Awvba",
  },
  fasiOrdineModificabili: [
    "CONFERMARE",
    "IMMESSO",
    "SPEDIBILE",
    "BLOCCATO",
    "RICHIAMARE",
    "ACCODATO",
    "RIFERITO",
    "URGENTE",
    "OFFERTA",
  ],
  firebaseConfig: {
    apiKey: "AIzaSyCp4ScihNZXc6inRk5dtgOVE1mEsjRISHw",
    authDomain: "cantine-2176a.firebaseapp.com",
    databaseURL: "https://cantine-2176a.firebaseio.com",
    projectId: "cantine-2176a",
    storageBucket: "cantine-2176a.appspot.com",
    messagingSenderId: "1055850811105",
    appId: "1:1055850811105:web:13397107a032be83",
  },
};
