import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { AppRoutes } from "./app.routing";
import { AppComponent } from "./app.component";

import { FlexLayoutModule } from "@angular/flex-layout";
import { FullComponent } from "./layouts/full/full.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./Material-module";

import { EmptyComponent } from "./layouts/empty/empty.component";
import { SocketIoConfig, SocketIoModule } from "ngx-socket-io";
import { environment } from "src/environments/environment";
import { TranslationsService } from "./services/translations.service";
import { SharedModule } from "./shared/shared.module";
const config: SocketIoConfig = { url: environment.socketUrl, options: {} };

function initializeApp(): Promise<any> {
  return new Promise((resolve, reject) => {
    // get url from navigator
    const url = window.location.href;
    if (url.includes(environment.base_url.it)) {
      environment.api_url_php.apigesthost = environment.api_url_php.urls.it;
      environment.lang = "it";
    } else if (url.includes(environment.base_url.de)) {
      environment.api_url_php.apigesthost = environment.api_url_php.urls.de;
      environment.lang = "de";
    } else {
      environment.api_url_php.apigesthost =
        environment.api_url_php.urls.default;
    }
    resolve(true);
  });
}

@NgModule({
  declarations: [AppComponent, FullComponent, EmptyComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    RouterModule.forRoot(AppRoutes),
    SocketIoModule.forRoot(config),
    SharedModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeApp,
      multi: true,
    },
    TranslationsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
