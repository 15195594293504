import { MediaMatcher } from "@angular/cdk/layout";
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import { TranslationsService } from "src/app/services/translations.service";
import { MenuItems } from "../../shared/menu-items/menu-items";

/** @title Responsive sidenav */
@Component({
  selector: "app-full-dream-layout",
  templateUrl: "full-dream.component.html",
  styleUrls: ["./full-dream.component.scss"],
  providers: [MenuItems],
})
export class FullDreamComponent implements OnDestroy, AfterViewInit {
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    public t: TranslationsService
  ) {
    this.mobileQuery = media.matchMedia("(min-width: 768px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() {}
}
