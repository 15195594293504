<div>
  <div class="body">
    <div class="login">
        <form [formGroup]="form" (ngSubmit)="verify()">
            <h3>  <mat-icon aria-hidden="false">lock</mat-icon> Inserire codice d'accesso</h3>
            <mat-form-field class="field-width">
                <mat-label>Codice</mat-label>
                <input type="text" matInput autocomplete="off" formControlName="code">
                <mat-error *ngIf="code!.invalid">
                  Inserire codice d'accesso
                </mat-error>
            </mat-form-field>
            <br>
            <button type="submit" class="field-width button-height" mat-raised-button color="primary" [disabled]="form.invalid">Accedi</button>
        </form>
    </div>
</div>
</div>

<ngx-loading-x [show]="loading"></ngx-loading-x>