import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { OrderService } from "src/app/services/order.service";
import { Socket } from "ngx-socket-io";
import { CryptService } from "src/app/services/crypt.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-security-code",
  templateUrl: "./security-code.component.html",
  styleUrls: ["./security-code.component.css"],
})
export class SecurityCodeComponent implements OnInit {
  form!: FormGroup;
  fasiOrdineModificabili = environment.fasiOrdineModificabili;

  @Output() onOrderLoaded = new EventEmitter<string>();

  @Input() hash!: string;
  public loading = false;
  securityCode!: string;

  constructor(
    private fb: FormBuilder,
    public crypt: CryptService,
    private orderSrv: OrderService,
    private socket: Socket
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      code: ["", Validators.required],
    });
  }

  get code() {
    return this.form.get("code");
  }

  verify() {
    if (this.form.valid) {
      this.loadOrder(this.code?.value);
    }
  }

  loadOrder(securityCode: string) {
    this.securityCode = securityCode;
    this.loading = true;
    this.orderSrv
      .getOrder(this.hash, this.securityCode)
      .subscribe((result: any) => {
        if (result.order) {
          if (!result.order.downloaded) {
            if (!result.lock) {
              if (
                this.fasiOrdineModificabili.includes(result.order.FaseOrdine) &&
                !result.order.downloaded
              ) {
                this.socket.emit("selectOrder", { orderId: result.order.id });
              }
            } else if (result.lock.user_id !== 0) {
              alert(
                "In questo momento l'ordine è in lavorazione, non è possibile modificarlo. Riprova piú tardi."
              );
            }
          } else {
            alert("L'ordine è chiuso");
          }

          this.onOrderLoaded.emit(result);

        } else {
          alert("L'ordine non esiste");
        }
        this.loading = false;
      });
  }
}
