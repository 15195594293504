import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface ICrypt {
    text: string
}

@Injectable()
export class CryptService {

    private headers: any;

    constructor(
        public http: HttpClient
    ) {

        this.headers = {
            headers: new HttpHeaders()
            .set('app', 'cantine')
            .set('secretkey', environment.api_url_php.secretkey)
            .set('Accept', 'application/json')
          };
    }

    crypt(val: string): Observable<ICrypt>  {
        let form: ICrypt = {
            text: val.toString()
        };

        return <Observable<ICrypt>> <unknown>this.http.post(environment.api_url_php.apigesthost + 'placeholder/crypt/it', form, this.headers);
    }

    decrypt(hash: string): Observable<ICrypt>  {
        let form: ICrypt = {
            text: hash
        };
        return <Observable<ICrypt>> <unknown>this.http.post(environment.api_url_php.apigesthost + 'placeholder/decrypt/it', form, this.headers);
    }
}
