import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class TranslationsService {

    data: any;
    constructor(
        private http: HttpClient
    ) {
        this.loadData().subscribe((data: any) => {
            this.data = data;
        });
    }

    setLanguage(lang: string) {
        localStorage.setItem('lang', lang);
    }

    loadData() {
        return this.http.get(`/assets/i18n/${localStorage.getItem('lang')}.json`);
    }

    t(key: string) {
        return this.data[key];
    }

}
